import { ref, watch } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { usePreferredColorScheme } from '@vueuse/core';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { useAuthStore } from './auth.store';

export const useAppStore = defineStore('app', () => {
  const authStore = useAuthStore();
  const { currentAccount, currentUser } = storeToRefs(authStore);
  const appBarTitle = ref('');
  const preferredColor = usePreferredColorScheme();
  const theme = ref(localStorage.getItem('theme') ?? preferredColor.value);

  let beamsClient;
  try {
    beamsClient = new PusherPushNotifications.Client({
      instanceId: '803a6c77-5b1c-4aaa-99e0-b1ce7b2912e7',
    });
  } catch (err) {
    console.error({ err });
  }

  watch([currentAccount, currentUser], (v) => {
    if (v[0] && v[1]) {
      try {
        beamsClient
          .start()
          .then(() =>
            beamsClient.addDeviceInterest(`${v[0]?.name}.user.${v[1]?.id}`)
          )
          .then(() => console.log('Successfully registered and subscribed!'))
          .catch(console.error);
      } catch (err) {
        console.error({ err });
      }
    }
  });

  return {
    beamsClient,

    appBarTitle,
    preferredColor,
    theme,
  };
});

import { useAuthStore } from '@/store/auth.store';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

export interface IApiResult<T> {
  code: string;
  message: string;
  payload: T;
  status: number;
}

export interface IApiPagination<T> {
  items: T[];
  first: number;
  before: number;
  previous: number;
  current: number;
  last: number;
  next: number;
  total_pages: number;
  total_items: number;
  limit: number;
}

// if (localStorage.getItem("jwtToken"))
//   axios.defaults.headers.common[
//     "Authorization"
//   ] = `Bearer ${localStorage.getItem("jwtToken")}`;

const instance = axios.create({
  baseURL: 'https://routes.expert/api/v1/',
});

instance.interceptors.request.use((config) => {
  const { token } = useAuthStore();
  if (!config.headers['X-Auth-Token']) {
    config.headers['X-Auth-Token'] = token;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const response = error.response as AxiosResponse;
    const requestConfig = error.config as AxiosRequestConfig;
    if (response.status === 401 && window.location.pathname != '/auth') {
      const { token } = storeToRefs(useAuthStore());
      token.value = null;
      setTimeout(() => {
        document.location.href = '/auth';
      }, 250);
    }
    return Promise.reject(error);
  }
);

export default instance;

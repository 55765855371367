import {
  EDeliveryServiceStatus,
  IDeliveryService,
  IDeliveryServiceStatus,
} from '@/api/deliveryServices.api';

export const INTERNAL_DELIVERY_SERVICE_STATUSES: {
  ORDER_FREE: IDeliveryServiceStatus;
  ORDER_STOCK: IDeliveryServiceStatus;
  GO_TO_TAKEOUT: IDeliveryServiceStatus;
  TAKE_ORDER_FROM_TAKEOUT: IDeliveryServiceStatus;
  GO_TO_DELIVERY: IDeliveryServiceStatus;
  GIVE_ORDER_TO_DELIVERY: IDeliveryServiceStatus;
  SUCCESS: IDeliveryServiceStatus;
  DEFAULT: IDeliveryServiceStatus;
} = {
  ORDER_FREE: {
    title: 'Заказ собирается',
    bgClass: 'bg-slate-200',
    borderClass: 'border-slate-500',
    textClass: 'text-slate-500',
  },
  ORDER_STOCK: {
    title: 'Заказ собран',
    bgClass: 'bg-cyan-200',
    borderClass: 'border-cyan-500',
    textClass: 'text-cyan-500',
  },
  GO_TO_TAKEOUT: {
    title: 'Направляйтесь в магазин',
    bgClass: 'bg-lime-200',
    borderClass: 'border-lime-500',
    textClass: 'text-lime-500',
  },
  TAKE_ORDER_FROM_TAKEOUT: {
    title: 'Заберите заказ из магазина',
    bgClass: 'bg-teal-200',
    borderClass: 'border-teal-500',
    textClass: 'text-teal-500',
  },
  GO_TO_DELIVERY: {
    title: 'Направляйтесь к клиенту',
    bgClass: 'bg-yellow-200',
    borderClass: 'border-yellow-500',
    textClass: 'text-yellow-500',
  },
  GIVE_ORDER_TO_DELIVERY: {
    title: 'Отдайте заказ клиенту',
    bgClass: 'bg-orange-200',
    borderClass: 'border-orange-500',
    textClass: 'text-orange-500',
  },
  SUCCESS: {
    title: 'Выполнен',
    bgClass: 'bg-green-200',
    borderClass: 'border-green-500',
    textClass: 'text-green-500',
  },
  DEFAULT: {
    title: 'Без статуса',
    bgClass: 'bg-slate-200',
    borderClass: 'border-slate-500',
    textClass: 'text-slate-500',
  },
};

export const useDeliveryServices = () => {
  function getStatus(ds: IDeliveryService): IDeliveryServiceStatus {
    if (ds.status_id == EDeliveryServiceStatus.Wait) {
      return INTERNAL_DELIVERY_SERVICE_STATUSES.ORDER_FREE;
    }

    if (ds.status_id == EDeliveryServiceStatus.Stock) {
      return INTERNAL_DELIVERY_SERVICE_STATUSES.ORDER_STOCK;
    }

    if (
      ds.pickups &&
      ds.pickups.filter(
        (item) => item.status_id == EDeliveryServiceStatus.InPath
      ).length
    ) {
      return INTERNAL_DELIVERY_SERVICE_STATUSES.GO_TO_TAKEOUT;
    }

    if (
      ds.pickups &&
      ds.pickups.filter(
        (item) => item.status_id == EDeliveryServiceStatus.Process
      ).length
    ) {
      return INTERNAL_DELIVERY_SERVICE_STATUSES.TAKE_ORDER_FROM_TAKEOUT;
    }

    if (
      ds.status_id == EDeliveryServiceStatus.InPath &&
      ds.pickups &&
      ds.pickups.filter(
        (item) => item.status_id == EDeliveryServiceStatus.Success
      ).length == ds.pickups.length
    ) {
      return INTERNAL_DELIVERY_SERVICE_STATUSES.GO_TO_DELIVERY;
    }

    if (
      ds.status_id == EDeliveryServiceStatus.Process &&
      ds.pickups &&
      ds.pickups.filter(
        (item) => item.status_id == EDeliveryServiceStatus.Success
      ).length == ds.pickups.length
    ) {
      return INTERNAL_DELIVERY_SERVICE_STATUSES.GIVE_ORDER_TO_DELIVERY;
    }

    if (ds.status_id == EDeliveryServiceStatus.Success) {
      return INTERNAL_DELIVERY_SERVICE_STATUSES.SUCCESS;
    }

    return INTERNAL_DELIVERY_SERVICE_STATUSES.DEFAULT;
  }

  return {
    getStatus,
  };
};

import { IApiPagination, IApiResult } from './index';
import { IApiError, useAxiosRequest } from '@/composables/api.composable';
import { TTailwindColor } from '@/utils/tailwind.util';

export interface IDeliveryServicesListRequestParams {
  page?: number;
  limit?: number;
  all_regions?: boolean;
  available?: boolean;
  taked?: boolean;
  closed?: boolean;
  without_dispatcher_cache?: boolean;
  date_start?: string;
  date_end?: string;
}
export enum EDeliveryServiceStatus {
  Wait = 1,
  Process,
  Stock,
  InPath,
  Returned,
  Destroy,
  CancelInProgress,
  CancelInPlace,
  Cancel,
  PartSuccess,
  Success,
}
export interface IDeliveryServiceStatus {
  title: string;
  bgClass: string;
  borderClass: string;
  textClass: string;
}
export interface IDeliveryService {
  id: number;
  number: string;
  delivery_user_id: number;
  work_object_id: number | null;
  status_id: EDeliveryServiceStatus;
  date: string;
  address: string;
  lat: string;
  lng: string;
  size: number | null;
  timewindows: string;
  duration: number;
  reward: number | null;
  optional: boolean | null;
  requires: boolean | null;
  phone: string;
  client: null | string;
  comment: null | string;
  cargo: string;
  custom_fields: null | any;
  created_at: string;
  updated_at: string;
  archive_at: string | null;
  regions_id: number | null;
  weight: null | string;
  volume: null | string;
  routes_id: null | number;
  routes_order: null | number;
  pay_type: null | string;
  prepay_type: null | string;
  prepay_part_type: null | string;
  prepay_part_amount: null | number;
  service_type: null | string;
  order_number: null | string;
  transport_type: null | string;
  foot_distance: null | string;
  foot_duration: null | string;
  bicycle_distance: null | string;
  bicycle_duration: null | string;
  car_distance: null | string;
  car_duration: null | string;
  osrm_calc: boolean;
  pickups: IDeliveryService[] | null;
}
export const useDeliveryServicesListRequest = () =>
  useAxiosRequest<
    IApiResult<IApiPagination<IDeliveryService>>,
    IDeliveryServicesListRequestParams,
    IApiError
  >({
    url: '/delivery-services',
    method: 'GET',
  });

export interface IDeliveryServicesSetStatusRequestParams {
  serviceId: number;
  statusId: EDeliveryServiceStatus;
}
export const useDeliveryServicesSetStatusRequest = () =>
  useAxiosRequest<
    IApiResult<IDeliveryService>,
    IDeliveryServicesSetStatusRequestParams,
    IApiError
  >({
    dynamicUrl: (p) => `/delivery-services/${p.serviceId}/status/${p.statusId}`,
    ignoreParamsKeys: ['serviceId', 'statusId'],
    method: 'PUT',
  });

export const useDeliveryServicesPickupAddressRequest = () =>
  useAxiosRequest<
    IApiResult<
      {
        address: string;
      }[]
    >,
    undefined,
    IApiError
  >({
    url: '/delivery-services/getAllPickupsAddress',
    method: 'GET',
  });

export interface IDeliveryServicesTakeRequestParams {
  serviceId: number;
}
export const useDeliveryServicesTakeRequest = () =>
  useAxiosRequest<
    IApiResult<IDeliveryService>,
    IDeliveryServicesTakeRequestParams,
    IApiError
  >({
    dynamicUrl: (p) => `/delivery-services/${p.serviceId}/take`,
    ignoreParamsKeys: ['serviceId'],
    method: 'GET',
  });

import { IAuthUser } from './auth.api';
import { IApiPagination, IApiResult } from './index';
import { IApiError, useAxiosRequest } from '@/composables/api.composable';
import { TTailwindColor } from '@/utils/tailwind.util';

export interface IUsersSendGeoRequestParams {
  lat: number;
  lng: number;
}
export const useUsersSendGeoRequest = () =>
  useAxiosRequest<any, IUsersSendGeoRequestParams>({
    dynamicUrl: (p) => `/users/geo?lat=${p.lat}&lng=${p.lng}`,
    method: 'POST',
  });

export interface IUsersDutyToggleRequestParams {
  value: boolean;
}
export const useUsersDutyToggleRequest = () =>
  useAxiosRequest<any, IUsersDutyToggleRequestParams>({
    url: '/users/duty',
    method: 'GET',
  });

export interface IUsersEditMeRequestParams {
  pickups_address: string | null | undefined;
}
export const useUsersEditMeRequest = () =>
  useAxiosRequest<IApiResult<IAuthUser>, IUsersEditMeRequestParams>({
    url: '/users/editMe',
    method: 'POST',
  });

import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import {
  IAuthAccount,
  IAuthUser,
  useAuthRequest,
  useAuthTokenCheckRequest,
} from '@/api/auth.api';
import { useRouter } from 'vue-router';

export const useAuthStore = defineStore('auth', () => {
  const router = useRouter();
  const token = ref<string | null>(localStorage.getItem('token'));
  const isLogin = ref<boolean>(!!token.value);
  const currentUser = ref<IAuthUser>();
  const currentAccount = ref<IAuthAccount | null>(
    localStorage.getItem('currentAccount')
      ? (JSON.parse(localStorage.getItem('currentAccount')!) as IAuthAccount)
      : null
  );
  const {
    request: loginRequest,
    loading: loginLoading,
    error: loginError,
  } = useAuthRequest();
  const {
    result: checkTokenResult,
    request: checkTokenRequest,
    loading: checkTokenLoading,
    error: checkTokenError,
  } = useAuthTokenCheckRequest();

  function login(username: string, password: string) {
    const basicToken = btoa(`${username}:${password}`);
    return loginRequest(undefined, {
      headers: {
        Authorization: `Basic ${basicToken}`,
      },
    }).then((r) => {
      token.value = r.payload.accounts[0].token;
      currentAccount.value = r.payload.accounts[0];
    });
  }

  function checkToken() {
    return checkTokenRequest(undefined, {
      headers: {
        'X-Auth-Token': token.value,
      },
    });
  }

  function logout() {
    token.value = null;
    router.push('/auth');
  }

  watch(checkTokenResult, (v) => {
    currentUser.value = v?.payload.user;
  });

  watch(token, (v) => {
    if (v) {
      localStorage.setItem('token', v);
    } else {
      localStorage.removeItem('token');
    }
    isLogin.value = !!v;
  });

  watch(currentAccount, (v) => {
    if (v) {
      localStorage.setItem('currentAccount', JSON.stringify(v));
    } else {
      localStorage.removeItem('currentAccount');
    }
  });

  return {
    token,
    currentAccount,
    currentUser,

    loginError,
    loginLoading,
    login,
    logout,

    checkTokenError,
    checkTokenLoading,
    checkToken,
  };
});

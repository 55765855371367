import { ref } from 'vue';
import { RawAxiosRequestConfig, AxiosError } from 'axios';
import axios from '@/api';

export interface IApiError {
  code: string;
  message: string;
}

export interface IConfig<D = any> extends RawAxiosRequestConfig<D> {
  dynamicUrl?: (p: D) => string;
  ignoreParamsKeys?: (keyof D)[];
}

export const useAxiosRequest = <T, D = any, E = any>(config: IConfig<D>) => {
  const result = ref<T>();
  const loading = ref(false);
  const error = ref<E | undefined>();

  const request = (p?: D, c?: RawAxiosRequestConfig<D>): Promise<T> => {
    if (c)
      config = {
        ...config,
        ...c,
      };

    if (config.dynamicUrl && p) {
      config.url = config.dynamicUrl(p);
    }

    if (p && config.ignoreParamsKeys) {
      config.ignoreParamsKeys.forEach((key) => delete p[key]);
    }

    if (config.method === 'GET' || config.method === 'DELETE') {
      config.params = p;
    } else {
      config.data = p;
    }

    loading.value = true;
    return new Promise((resolve, reject) => {
      if (!config.url) {
        reject(new Error('config.url is not defined'));
        throw new Error('config.url is not defined');
      }
      axios
        .request<T>(config)
        .then((r) => {
          result.value = r.data;
          resolve(result.value);
        })
        .catch((e: AxiosError<E>) => {
          error.value = e.response?.data;
          reject(error.value);
        })
        .finally(() => {
          loading.value = false;
        });
    });
  };

  return {
    loading,
    error,
    result,
    request,
  };
};

import { IApiResult } from './index';
import { IApiError, useAxiosRequest } from '@/composables/api.composable';

export interface IAuthAccount {
  name: string;
  domain: string;
  secret: string;
  token: string;
}
export interface IAuthRequestResult {
  accounts: IAuthAccount[];
}
export const useAuthRequest = () =>
  useAxiosRequest<IApiResult<IAuthRequestResult>, undefined, IApiError>({
    url: '/auth',
    method: 'GET',
  });

export interface IAuthUser {
  id: string;
  first_name: string;
  middle_name: string;
  email: string;
  phone: string;
  pickups_address: string | null;
  is_admin: boolean;
  on_duty: boolean;
  capacity: number;
  volume: number;
  max_delivery_services_count: number;
  groups_id: number;
  rights_id: number;
  regions_id: number;
  created_at: string;
  updated_at: string;
  archive_at: string;
  block_at: string;
}
export interface IAuthTokenCheckRequestResult {
  expires_at: string;
  user: IAuthUser;
}
export const useAuthTokenCheckRequest = () =>
  useAxiosRequest<
    IApiResult<IAuthTokenCheckRequestResult>,
    undefined,
    IApiError
  >({
    url: '/auth/token/check',
    method: 'GET',
  });

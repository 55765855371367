import { useUsersSendGeoRequest } from './../api/users.api';
import { useGeolocation, useThrottleFn } from "@vueuse/core";

export const useUserGeo = () => {
  const ug = useGeolocation();
  const { coords } = ug;
  const { request } = useUsersSendGeoRequest();
  const sendGeoThrottle = useThrottleFn(() => {
    console.log('sendGeoThrottle');
    request({
      lat: coords.value.latitude,
      lng: coords.value.longitude,
    });
  }, 5000);

  return {
    ...ug,
    sendGeoThrottle
  }
}